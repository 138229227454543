import { Component, inject } from '@angular/core';
import { SearchInputComponent } from '../search-input/search-input.component';
import { BtnPrimaryComponent } from '../buttons/btn-primary/btn-primary.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { IDidCountries, IPackageCountries } from 'src/app/services/models/countries.model';

@Component({
  selector: 'app-search-countries',
  standalone: true,
  imports: [SearchCountriesComponent, SearchInputComponent, BtnPrimaryComponent, TranslocoModule],
  templateUrl: './search-countries.component.html',
  styleUrl: './search-countries.component.scss'
})
export class SearchCountriesComponent {
  virtualNumberCountry: IDidCountries = null;
  packageCountry: IPackageCountries = null;
  submitOrderService = inject(SubmitOrderService);

  countriesForm = this.submitOrderService.submitForm.get('countriesForm') as FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.countriesForm.reset()
  }

  onVirtualNumberCountrySelected(country: IDidCountries) {
    this.countriesForm.get('virtualNumberCountry').setValue(country.countryName);
    this.virtualNumberCountry = country
  }

  onPackageCountrySelected(country: IPackageCountries) {
    this.countriesForm.get('packageCountry').setValue(country.countryName);
    this.packageCountry = country
  }

  getLink(): string {
    const countryName = this.virtualNumberCountry?.countryName?.toLowerCase().replace(" ", "-");
    const countryId = this.virtualNumberCountry?.countryID;
    const packageCountry = this.packageCountry?.countryName?.toLowerCase().replace(" ", "-");
    return `/choose-your-number/${countryName}/${countryId}/${packageCountry}/${this.packageCountry?.packageId}`;
  }


}
